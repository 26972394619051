<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      no-fade
      spinner-variant="primary"
    >
      <b-card class="text-size-header">
        <b-row>
          <b-col md="6">
            <strong>User:</strong> {{ userPermissions != null ? userPermissions.name : '' }}
          </b-col>
          <b-col md="6">
            <v-select
              v-model="role_id"
              label="name"
              :options="rolesList"
              :reduce="role => role.id"
              :placeholder="$t('roles.selectRole')"
              :selectable="(item) => item.status"
            >
              <div slot="no-options">
                {{ $t('generic.noRecordsFound') }}
              </div>
            </v-select>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
    <div
      v-for="(modules, index) in moduleList"
      :key="modules.id"
    >
      <b-card
        v-if="validateIfThePermissionCanBeShownByModule(modules)"
        class="card-collapse"
      >
        <b-overlay
          :show="show || loading"
          rounded="sm"
          no-fade
          spinner-variant="primary"
        >
          <b-row class="header-collapse align-items-center">
            <b-col
              md="11"
              class="activate"
              @click="collapseMenu('permissions-' + (index + 1), modules.status)"
            >
              <b-form-group style="white-space:nowrap;">
                <b-row>
                  <b-col md="2">
                    <strong>{{ modules.name }}</strong>
                  </b-col>
                  <b-col v-if="checkPermission(['CHANGE_STATUS_PERMISSIONS','ALLOW_ALL'])">
                    <b-form-checkbox
                      v-model="modules.status"
                      name="check-button"
                      class="ml-2"
                      switch
                      :checked="modules.status"
                      :value="1"
                      :unchecked-value="0"
                      inline
                      @change="statusModule(modules, modules.status)"
                    >
                      <strong>{{ modules.status ? $t('generic.disableModule') : $t('generic.enableModule') }}</strong>
                    </b-form-checkbox>
                  </b-col>
                  <b-col v-else-if="modules.status === 1">
                    <strong>{{ $t('generic.enabled') }} </strong>
                    <i class="icon-color-primary icon-0-icons-bright-checkmark" />
                  </b-col>
                  <b-col v-else>
                    <strong>{{ $t('generic.disabled') }} </strong>
                    <i class="icon-0-icons-dark-control-center-nok" />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-collapse
            :id="'permissions-' + (index + 1)"
            class="mt-2"
            :visible="modules.status == 1"
          >
            <div
              v-for="submodule in modules.submodules"
              :key="submodule.id"
              fluid
            >
              <template v-if="validateIfThePermissionCanBeShownBySubmodule(submodule)">
                <b-container fluid>
                  <b-row class="pt-1 pb-1 mb-1 mt-1 bg-light">
                    <b-col md="2">
                      <strong>{{ submodule.name }}</strong>
                    </b-col>
                    <b-col v-if="checkPermission(['CHANGE_STATUS_PERMISSIONS','ALLOW_ALL'])">
                      <b-form-checkbox
                        v-model="submodule.status"
                        name="check-button"
                        switch
                        :checked="submodule.status"
                        :value="1"
                        :unchecked-value="0"
                        inline
                        @change="statusModule(submodule, submodule.status)"
                      >
                        <strong>{{ submodule.status ? $t('generic.disableSubmodule') : $t('generic.enableSubmodule') }}</strong>
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-for="permission in submodule.permissions"
                      :key="permission.id"
                      cols="6"
                      lg="2"
                      md="3"
                      class="mb-1"
                    >
                      <!-- <template v-if="validateIfThePermissionCanBeShown(permission.key)"> -->
                      <template>
                        <label
                          v-b-tooltip.hover.top
                          :title="permission.description"
                          variant="outline-secondary"
                        >
                          {{ permission.name }}
                        </label>&nbsp;
                        <b-form-checkbox
                          v-if="checkPermission(['CHANGE_STATUS_PERMISSIONS','ALLOW_ALL'])"
                          v-model="permission.status"
                          :disabled="!submodule.status"
                          switch
                          :checked="permission.status"
                          :value="1"
                          :unchecked-value="0"
                          @change="statusPermission(permission, permission.status, submodule)"
                        />
                        <span v-else-if="permission.status === 1">
                          <i class="icon-color-primary icon-0-icons-bright-checkmark" />
                        </span>
                        <span v-else>
                          <i class="icon-0-icons-dark-control-center-nok" />
                        </span>
                      </template>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
            </div>
          </b-collapse>
        </b-overlay>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
import store from '@/store'
import VSelect from 'vue-select'
import checkPermission from '@/auth/permissions'
import servicesUser from '@/services/userService'
import servicePermission from '@/services/permissionService'

export default {
  components: {
    VSelect,
  },

  data() {
    return {
      show: false,
      loading: false,
      moduleList: [],
      rolesList: [],
      role_id: null,
      exceptionModules: [],
      exceptionPermissions: [],
      userPermissions: {
        name: null,
      },
    }
  },

  watch: {
    role_id(curentValue, previousValue) {
      if (previousValue !== null) {
        this.getUserData(this.$route.params.id, true)
      }
    },
  },

  mounted() {
    this.checkPermission(['VIEW_PERMISSIONS'])
      ? this.getDataInitials()
      : this.$router.push({ name: 'not-authorized' })
  },

  methods: {
    checkPermission,
    async getDataInitials() {
      try {
        await this.getUserData(this.$route.params.id, false)
        await this.getUserData(this.$route.params.id, true)
      } catch (error) {
        this.responseCatch(error)
      }
    },
    collapseMenu(idMenu, status) {
      if (status) {
        this.$root.$emit('bv::toggle::collapse', idMenu)
      }
    },
    getUserData(userId, getPermissions) {
      const params = getPermissions
        ? { options: 'assignedPermits', role: this.role_id }
        : { included: 'roles' }

      this.show = true

      return servicesUser.getUser(userId, params)
        .then(({ data }) => {
          if (getPermissions) {
            this.exceptionModules = data.data.exception_modules
            this.exceptionPermissions = data.data.exception_permissions
            const { modules } = data.data
            const allowedModules = this.filterModulesWithActiveOrExceptionStatus(modules)
            this.moduleList = [...allowedModules]
          } else {
            const { roles } = data.data
            this.userPermissions.name = data.data.full_name
            this.rolesList = roles.map(role => ({
              id: role.hash,
              name: role.name,
              status: role.status,
            }))

            if (this.rolesList.length) {
              this.role_id = this.rolesList[0].id
            }
          }

          this.show = false
        }).catch(error => {
          this.show = false
          this.responseCatch(error)
        })
    },
    filterModulesWithActiveOrExceptionStatus(modules) {
      let moduleList = []

      modules.forEach(module => {
        if (this.checkPermission([module.special_key])) {
          const exception = this.exceptionModules.find(item => item.module_hash === module.hash)

          if (exception !== undefined || module.active) {
            moduleList.push({
              id: module.hash,
              status: module.active ? 1 : 0,
              name: module.labelables[0].label,
              submodules: this.filterSubmodules(module.submodules),
            })
          }
        }
      })

      return moduleList
    },
    filterSubmodules(submodules) {
      let submoduleList = []

      submodules.forEach(submodule => {
        const exception = this.exceptionModules.find(item => item.module_hash === submodule.hash)

        if (exception !== undefined || submodule.active) {
          if (this.validateIfThePermissionCanBeShownBySubmodule(submodule)) {
            submoduleList.push({
              id: submodule.hash,
              status: submodule.active ? 1 : 0,
              name: submodule.labelables[0].label,
              permissions: this.filterPermissions(submodule.permissions),
            })
          }
        }
      })

      return submoduleList
    },
    filterPermissions(permissions) {
      let permissionList = []

      permissions.forEach(permission => {
        if (this.checkPermission([permission.key])) {
          if (permission.active) {
            permissionList.push({
              id: permission.hash,
              name: permission.action.labelables[0].label,
              key: permission.key,
              status: permission.active ? 1 : 0,
            })
          } else {
            const exceptionsList = this.exceptionPermissions.filter(item => item.permission.key == permission.key)

            if (exceptionsList.length) {
              permissionList.push({
                id: permission.hash,
                name: permission.action.labelables[0].label,
                key: permission.key,
                status: permission.active ? 1 : 0,
              })
            }
          }
        }
      })

      return permissionList
    },
    statusModule(module, status) {
      const params = {
        user_id: this.$route.params.id,
        role_id: this.role_id,
        module_id: module.id,
        status,
      }

      servicePermission.statusExceptionModule(params)
        .then(() => {
          this.getUserData(this.$route.params.id, true)
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    statusPermission(permission, status, submodule) {
      const params = {
        user_id: parseInt(this.$route.params.id, 10),
        role_id: parseInt(this.role_id, 10),
        permission_id: parseInt(permission.id, 10),
        module_id: parseInt(submodule.id, 10),
        status,
      }

      servicePermission.statusExceptionPermission(params)
        .then(() => {
          this.getUserData(this.$route.params.id, true)
        }).catch(error => {
          this.responseCatch(error)
        })
    },
    hasAllPermissions(module) {
      if (!module.submodules.length) {
        return 0
      }

      let counter = 0

      for (let i = 0; i < module.submodules.length; i++) {
        if (module.submodules[i].status == 0) {
          counter += 1
          break
        } else {
          const index = module.submodules[i].permissions.findIndex(permission => permission.status == 0 && this.checkPermission([permission.key]))

          if (index !== -1) {
            counter += 1
            break
          }
        }
      }

      return (counter == 0) ? 1 : 0
    },
    updatePermissionsByCurrentModule(module, status) {
      if (module.submodules.length) {
        const params = {
          role_id: parseInt(this.$route.params.id, 10),
          module_id: module.id,
          submodules: this.getSubmoduleList(module.submodules),
          status,
        }

        servicePermission.updatePermissionsByCurrentModule(params)
          .then(() => {
            this.getRolData(this.$route.params.id)
          }).catch(error => {
            this.responseCatch(error)
          })
      }
    },
    getSubmoduleList(submodules) {
      return submodules.map(submodule => ({
        submodule_id: submodule.id,
        permissions: this.getPermissionList(submodule),
      }))
    },
    getPermissionList(submodule) {
      let permissionList = []

      submodule.permissions.forEach(permission => {
        if (this.checkPermission([permission.key])) {
          permissionList.push(permission.id)
        }
      })

      return permissionList
    },
    validateIfThePermissionCanBeShownBySubmodule(currentSubmodule) {
      let permissionFiltered = []

      currentSubmodule.permissions.forEach(item => {
        if (this.checkPermission([item.key])) {
          permissionFiltered.push(item.key)
        }
      })

      return permissionFiltered.length > 0
    },
    validateIfThePermissionCanBeShownByModule(currentModule) {
      let permissionFiltered = []

    

      currentModule.submodules.forEach(submodule => {
        submodule.permissions.forEach(item => {
          if (this.checkPermission([item.key])) {
            permissionFiltered.push(item.key)
          }
        })
      })

      return permissionFiltered.length > 0
    },
  },
}
</script>

<style>
.custom-control.custom-switch > .custom-control-label {
    margin-bottom: 0px;
}
.icon-color-primary {
  color: #6abeff;
}
</style>
